<template>
  <base-table :data="table.data"
              :columns="table.columns"
              thead-classes="text-primary">
  </base-table>
</template>
<script>
  import { BaseTable } from "@/components";
  import StatServices from '@/services/StatServices'

  export default {
    components: {
      BaseTable
    },
    data(){
      return{
        table:{
          columns:[
            {
              props: 'name',
              label: 'Document'
            },
            {
              props: 'count',
              label: 'Nbre de Vue'
            },
          ],
          data:[]
        }
      }
    },

    async mounted(){
      try {
        this.table.data = (await StatServices.getDocTop()).data
      } catch (error) {
        this.$notify({verticalAlign: 'bottom',type: 'danger', horizontalAlign: 'right', message: error.response.data.error});
      }
    }
  }
</script>
<style>
</style>
