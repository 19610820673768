import Axios from 'axios'
import config from './Config'
import Api from './Api'
export default {
  index() {
    return Api().get('promoters')
  },
  insert(formData) {
    return Api().post('promoters', formData)
  },
  update( id ,form) {
    return Api().put(`promoters/${id}/`, form)
  },
  delete(id) {
    return Api().delete(`promoters/${id}/`)
  },
  url() {
    return config.baseUrl + 'promoters'
  },
 
}
