<template>
  <div class="row">
    <div>
    <el-table
        :data="displayData" style="width:100%">
        <el-table-column min-width="50" prop='idclasses' label="#"></el-table-column>
        <el-table-column
            prop="name"
            label="Nom">
        </el-table-column>
        <el-table-column min-width="150"
                              header-align="right"
                              label="Actions">
                <template slot="header" :slot-scope="{scope}">
                  <base-input
                    v-model="search"
                    size="mini"
                    :placeholder="$t('formField.search')"/>
                </template>
                <div slot-scope="scope" class="text-right">
                  <el-tooltip :content="$t('formButton.edit')"
                              :open-delay="300"
                              
                              placement="top">
                    <base-button type="success" size="sm" icon @click="edit(scope.row)">
                      <i class="tim-icons icon-settings"></i>
                    </base-button>
                  </el-tooltip>

                  <el-tooltip :content="$t('formButton.delete')"
                              :open-delay="300"
                              placement="top">
                    <base-button type="danger" size="sm" icon @click="deleted(scope.row)">
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </el-tooltip>

                </div>
              </el-table-column>
    </el-table>
    </div>
    <el-divider></el-divider>

    <div style="text-align: center">
        <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            :total="total">
        </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
       filtered: [],
        search: '',
        page: 1,
        pageSize: 4,
        total: 0,
        categories: [{
          idclasses: 1,
          name: 'Seconde Le',
        },
        {
          idclasses: 3,
          name: 'Terminale C',
        },
        {
          idclasses: 7,
          name: 'Terminale B',
        }, 
        {
          idclasses: 5,
          name: 'Troisième',
        },
        {
          idclasses: 6,
          name: 'Première S',
        }]
      }
  },
  computed: {
      searching() {
          if (!this.search) {
              this.total = this.categories.length;
              return this.categories;
          }
          this.page = 1;
          return this.categories.filter(data => data.name.toLowerCase().includes(this.search.toLowerCase()));
      },
      displayData() {
          this.total = this.searching.length;

          return this.searching.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
      }
  },
  methods: {
      handleCurrentChange(val) {
          this.page = val;
      },
  }
};
</script>
<style></style>
