<template>
<div>
  <div class="row">
      <div class="col-12">
        <card type="nav-tabs">
          <div slot="header" class="card-header-success">
            <div class="row">
              <div class="col-sm-6" :class="'text-left'">           
                <h2 class="card-title"> {{title}} - {{edit? $t('tools.update'):$t('tools.create')}}</h2>
              </div>
              <div class="col-sm-6">
                <div class="btn-group"
                     :class="'float-right'">
                 <base-button size="sm" type="primary" @click="handleClick">
                  {{$t('tools.back')}}
                 </base-button>
                </div>
              </div>
            </div>
          
          </div>
          <blockquote class="blockquote mb-0">
            <ValidationObserver v-slot="{ invalid }">
              <form @submit.prevent="onSubmit">
                <validation-provider rules="required|min:3" v-slot="{ passed,  failed,   errors }">
                  <base-input
                  required
                  v-model="value"
                  type="text"
                  :label="$t('formField.name')"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, {'is-invalid': failed},{'has-danger': failed}]"
                  >
                  </base-input>
                </validation-provider>
                <base-checkbox v-model="stay">
                    {{$t('formField.stayOnPage')}}
                </base-checkbox>
                <base-button native-type="submit" type="primary" :disabled="invalid">{{edit? $t('formButton.edit'): $t('formButton.add')}}</base-button>
              </form>
            </ValidationObserver>
          </blockquote>
        </card>
      </div>
    </div>
</div>
</template>

<script>
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required, min } from 'vee-validate/dist/rules';
import { configure } from 'vee-validate';

extend('required', {
  ...required,
  message: 'Vous devez renseigner un nom'
});
extend('min', {
  ...min,
  message: 'Vous devez renseigner au moins 3 caractères'
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props:{
    title: String,
    edit: Boolean,
    row: Object
  },

  data() {
    return {
      stay:false,
      value:''
    }
  },
  methods:{
    handleClick(evt) {
      this.$emit('back-to-list', evt);
    },
    onSubmit () {
      
      if(this.edit){
        const key = Object.keys(this.row)[0];
        this.$emit('updated',this.row[key],this.value)
      }else{
        this.$emit('created',this.value)
      }
      if(!this.stay){
        this.handleClick();
      }
    }
  },
  mounted(){
    if(this.row){
      this.value = this.row.name
    }
  }
}
</script>

