<template>
<div>
  <div class="row">
      <div class="col-12">
        <card type="nav-tabs">
            <div class="form-row">
                <base-input
                required
                v-model="daterange"
                label="Sélectionnez une plage"
                class="col"
                >
                <div >
                    <el-date-picker
                    v-model="daterange"
                    type="daterange"
                    size="large"
                    range-separator="à"
                    start-placeholder="Date de début"
                    end-placeholder="Date de fin"
                    format="dd/MM/yyyy"
                    @change="filter"
                    >
                    </el-date-picker>
                </div>
                </base-input>
               <base-input
                required
                v-model="totalSolde"
                label="Montant généré sur la période" 
                class="col"
                > <h3> {{totalSolde}}  F CFA</h3> </base-input>
                <base-input
                required
                label="Nbre d'abonnement réussi sur la période " 
                class="col"
                > <h3> {{nbreAbo}}</h3> </base-input>
            </div>
            

                <div class="form-row">
                  <base-input v-model="select" :label="$t('formField.promoters')" class="col">
                    <div>
                      <el-select class="select-danger"
                                :placeholder="$t('formField.selectPromoters')"
                                selectedable
                                v-model="select" @change="filterPromo" :clearable="true" >
                        <el-option v-for="option in promoters" 
                                  class="select-danger"
                                  :value="option.idpromoters"
                                  :label="option.name"
                                  :key="option.idpromoters">
                        </el-option>
                      </el-select>
                    </div>
                  </base-input>
                  <base-input
                required
                label="Montant généré sur la période par le promoteur" 
                class="col"
                > <h3> {{totalSoldePromo}}  F CFA</h3> </base-input>
                <base-input
                required
                label="Nbre d'abonnement réussi sur la période " 
                class="col"
                > <h3> {{nbreAboPromo}}</h3> </base-input>
                <base-input
                required
                label="Montant gagné par le promoteur" 
                class="col"
                > <h3> {{soldePromo}}  F CFA</h3> </base-input>
                </div>
        </card>
      </div>
    </div>
</div>
</template>

<script>

import PromoterService from '@/services/PromoterService'

export default {
  props:{
    rows: Array
  },

  data() {
    return {
      daterange:'',
      promoters: [],
      select: ""
      
    }
  },
  computed:{
      totalSolde(){
          var total = 0
          for(const row of this.filteredRows){
              if(row.status === "201" ){
                  total = total + row.price
              }
          }
          return total
      },
      filteredRows(){
          return this.daterange ? this.rows.filter(row => this.$date(row.createdAt).isBetween(this.daterange[0],this.daterange[1],'[]')) : this.rows
      },
    nbreAbo(){
          var total = 0
          for(const row of this.filteredRows){
              if(row.status === "201" ){
                  total++
              }
          }
          return total
      },
      filteredPromoters(){
        return this.select ? this.filteredRows.filter(row => row.idcodes === this.select) : this.filteredRows
      },
      totalSoldePromo(){
          var total = 0
          for(const row of this.filteredPromoters){
              if(row.status === "201" ){
                  total = total + row.price
              }
          }
          return this.select ? total : 0
      },
      nbreAboPromo(){
          var total = 0
          for(const row of this.filteredPromoters){
              if(row.status === "201" ){
                  total++
              }
          }
          return this.select ? total : 0
      },
      soldePromo(){
          return this.nbreAboPromo * 1000
      }

  },
  methods:{
    filter() {
      this.$emit('filter', this.filteredRows);
    
    },
    filterPromo() {
        console.log('promo')
      this.$emit('filter', this.filteredPromoters);
    },
    async getPromoters(){
      try {
        const rep= (await PromoterService.index()).data
        this.promoters = rep.rows
      } catch (error) {
        this.notifyError(error.response.data.error)
      }
    },
   
  },
  mounted(){
   
    this.getPromoters()    
  }
}
</script>

