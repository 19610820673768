import Axios from 'axios'
import config from './Config'
import Api from './Api'
export default {
  index() {
    return Api().get('typedocs')
  },
  insert(name) {
    return Api().post('typedocs', {name})
  },
  update(id,name) {
    return Api().put(`typedocs/${id}/`, { name })
  },
  delete(id) {
    return Api().delete(`typedocs/${id}/`)
  },
  url() {
    return config.baseUrl + 'typedocs'
  },
  getStatDoc() {
		return Api().get('typedocs/stats')
	},
}
