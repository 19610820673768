import Axios from 'axios'
import config from './Config'
import Api from './Api'
export default {
  index() {
    return Api().get('subjects')
  },
  insert(name) {
    return Api().post('subjects', {name})
  },
  update(id, name) {
    return Api().put(`subjects/${id}/`, { name })
  },
  delete(id) {
    return Api().delete(`subjects/${id}/`)
  },
  url() {
    return config.baseUrl + 'subjects'
  },
  getStatDoc() {
		return Api().get('subjects/stats')
	},
}
