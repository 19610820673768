<template>
<div>
  <add v-if="add"  @click="add = !add"/>
  <div v-else @click="add = !add">
    <student-chart></student-chart>
    <view-list ></view-list>
  </div>
  
</div>
</template>

<script>
import { BaseTable } from "@/components";
import Add from './Tools/Add.vue';
import ViewList from './Tools/ViewList.vue';
import StudentChart from './Student/StudentChart.vue';

export default {
  components: {
    BaseTable,
    Add,
    ViewList,
    StudentChart
  },
  data() {
    return {
      add:false
    }
  }
}
</script>

