import VueRouter from "vue-router";
import routes from "./starterRouter";
import store from '@/store/store'


// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (store.state.token == null) {
			next({
				path: '/login',
				params: { nextUrl: to.fullPath },
			})
		} else {
			let user = store.state.user
			if (to.matched.some((record) => record.meta.is_access)) {
				if (store.state.access.includes(user.role)) {
					next()
				} else {
					next({ name: 'dashboard' })
				}
			} else if (to.matched.some((record) => record.meta.is_privilege)) {
				if (store.state.privilege.includes(user.role)) {
					next()
				} else {
					next({ name: 'dashboard' })
				}
			}
			else if (to.matched.some((record) => record.meta.is_prestige)) {
				if (store.state.prestige.includes(user.role)) {
					next()
				} else {
					next({ name: 'dashboard' })
				}
			}else {
				next()
			}
		}
	} else if (to.matched.some((record) => record.meta.guest)) {
		if (store.state.token == null) {
			next()
		} else {
			next({ name: 'dashboard' })
		}
	} else {
		next()
	}
})


export default router;
