import Axios from 'axios'
import config from './Config'
import Api from './Api'
export default {
  index() {
    return Api().get('activation_codes')
  },
  insert(id,formData) {
    return Api().post(`promoters/${id}/activation_codes`, formData)
  },
  update( id ,form) {
    return Api().put(`activation_codes/${id}/`, form)
  },
  delete(id) {
    return Api().delete(`activation_codes/${id}/`)
  },
  url() {
    return config.baseUrl + 'activation_codes'
  },
 
}
