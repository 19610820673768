<template>
<div>
  <div class="row">
      <div class="col-12">
        <card >
          <template slot="header">
            <div class="row">
              <div class="col-sm-6" :class="'text-left'">           
                <h2 class="card-title">{{title}} - {{$t('tools.list')}}</h2>
              </div>
              <div class="col-sm-6">
                <div class="btn-group"
                     :class="'float-right'">
                 <base-button size="sm" type="primary" @click="handleClick">
                  {{$t('tools.add')}}
                 </base-button>
                </div>
              </div>
            </div>
          </template>
            <vue-bootstrap4-table :rows="rows" :columns="columns" :config="config"  @on-all-select-rows="onSelected" @on-all-unselect-rows="onSelected" @on-select-row="onSelected" @on-unselect-row="onSelected"
            :actions="actions"
            @refresh-data="onRefreshData"
            @on-delete-multiple="onDeleteMultiple">
              <template slot="refresh-button-text">
                  <i class="fas fa-sync-alt"></i> {{$t('formButton.refresh')}}
              </template>
              <template slot="reset-button-text">
                  <i class="fas fa-broom"></i> {{$t('formButton.reset')}}
              </template>
               <template slot="classes" slot-scope="props">
                {{props.cell_value.join('\n')}}
              </template>
              <template slot="status" slot-scope="props">
                <el-switch
                  :value="props.cell_value"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </template>
              <template slot="nocorrection" slot-scope="props">
                <el-switch
                  :value="!props.cell_value ? true : false"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </template>
              <template slot="statusAnswer" slot-scope="props">
                <el-switch
                  :value="props.cell_value ? true : false"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </template>
              <template slot="operation" slot-scope=props>
                <el-tooltip :content="$t('formButton.edit')"
                              :open-delay="300"
                              placement="top">
                    <base-button type="success"  size="sm" icon @click="edit(props.row)">
                      <i class="tim-icons icon-settings"></i>
                    </base-button>
                  </el-tooltip>

                  <el-tooltip :content="$t('formButton.delete')"
                              :open-delay="300"
                              placement="bottom">
                    <base-button type="danger" size="sm" v-if="$store.state.privilege.includes($store.state.user.role)" icon @click="deleted(props.row)">
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </el-tooltip>
              </template>
              <template slot="pagination-info" slot-scope="props">
                {{ props.currentPageRowsLength ? $t('tables.pagination', {
                  countRow: props.currentPageRowsLength, 
                  countTotal:props.originalRowsLength,
                  countFilter: props.filteredRowsLength   
                }) : ''}}
              </template>
            </vue-bootstrap4-table>
        </card>
      </div>
    </div>
</div>
</template>

<script>
import VueBootstrap4Table from 'vue-bootstrap4-table'

export default {
  components: {
    VueBootstrap4Table
  },
  props:{
    title:String,
    rows:Array
  },
  data() {
    return {
      selectedRow : [],
      config: {
        checkbox_rows: true,
        rows_selectable: false,
        per_page: 25, // default 10
        pagination: true,
        per_page_options: [10, 25, 50, 100],
        rows_selectable: true,
        highlight_row_hover_color: "#313846",
        global_search: {
            placeholder: this.$t('formField.search'),
            visibility: true,
            case_sensitive: false
        },
      },
      actions: [],
      columns: [{
              label: "#",
              name: "iddocuments",
              sort: true,
          },
          {
              label: this.$t('formField.name'),
              name: "name",
              filter: {
                  type: "simple",
                  placeholder: this.$t('formField.name')
              },
              sort: true,
          },
          {
              label: "Classe",
              name: "classes",
              filter: {
                  type: "simple",
                  placeholder: this.$t('formField.class')
              },
              sort: true,
          },
          {
              label: "Matière",
              name: "subjects",
              filter: {
                  type: "simple",
                  placeholder: this.$t('formField.subject')
              },
              sort: true,
          },
          {
              label: "Catégorie",
              name: "typedocs",
              filter: {
                  type: "simple",
                  placeholder: this.$t('formField.typedocs')
              },
          },
           {
              label: "Année",
              name: "year",
              filter: {
                  type: "simple",
                  placeholder: this.$t('formField.year')
              },
              sort: true,
          },
          
          {
              label: "Disp. Doc",
              name: "status",
              sort: true,
              filter: {
                type: "select",
                mode: "single",
                closeDropdownOnSelection: true,
                placeholder: "Disp. doc",
                options: [{
                        "name": "Gratuit",
                        "value": false
                    },
                    {
                        "name": "Payant",
                        "value": true
                    }
                ]
              }
          },
          {
              label: "Correction",
              name: "nocorrection",
               filter: {
                type: "select",
                mode: "single",
                closeDropdownOnSelection: true,
                placeholder: "Cor.",
                options: [{
                        "name": "Non",
                        "value": 1
                    },
                    {
                        "name": "Oui",
                        "value": 0
                    }
                ]
              },
              sort: true,
          },
          {
              label: "Disp. Cor",
              name: "statusAnswer",
              filter: {
                type: "select",
                mode: "single",
                closeDropdownOnSelection: true,
                placeholder: "Disp. Cor",
                options: [{
                        "name": "Gratuit",
                        "value": 0
                    },
                    {
                        "name": "Payant",
                        "value": 1
                    }
                ]
              },
              sort: true,
          },
          {
              label: "",
              name: "operation",
          },
      ],
      
    }
  },
  methods:{
    handleClick(evt,row) {
      this.$emit('add', evt,row);
    },
    onRefreshData(){
      this.$emit('refresh',row)
    },
    edit(row){
      this.handleClick('edit',row);
    },
    deleted(row){
      this.$emit('deleted',row)
    },
    onSelected(list){
      this.selectedRow = list.selected_items
      if(this.selectedRow.length>1){
        this.actions = [
        {
            btn_text: "Supprimer",
            event_name: "on-delete-multiple",
            class: "btn btn-primary my-custom-class ",
        }
      ]
      }else{
        this.actions = []
      }
    },
    onDeleteMultiple(){
      this.$emit('delete-multiple', this.selectedRow)
      this.selectedRow=[]
      this.actions=[]
    }
    
  }
}
</script>

