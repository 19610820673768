<template>
  <div class="wrapper" >
    <side-bar v-if="$store.state.isUserLoggedIn" >
      <template slot="links">
        <sidebar-link v-if="$store.state.access.includes($store.state.user.role)" to="/dashboard" :name="$t('sidebar.dashboard')" icon="tim-icons icon-chart-pie-36"/>
        <sidebar-link v-if="$store.state.privilege.includes($store.state.user.role)" to="/classes" :name="$t('sidebar.class')" icon="tim-icons icon-bank"/>
        <sidebar-link v-if="$store.state.privilege.includes($store.state.user.role)" to="/subjects" :name="$t('sidebar.subject')" icon="tim-icons icon-book-bookmark"/>
        <sidebar-link v-if="$store.state.privilege.includes($store.state.user.role)" to="/associate" :name="$t('sidebar.associatesubxclass')" icon="tim-icons icon-puzzle-10"/>
        <sidebar-link v-if="$store.state.privilege.includes($store.state.user.role)" to="/types" :name="$t('sidebar.type')" icon="tim-icons icon-single-copy-04"/>
        <sidebar-link v-if="$store.state.access.includes($store.state.user.role)" to="/documents" :name="$t('sidebar.document')" icon="tim-icons icon-paper"/>
        <sidebar-link v-if="$store.state.privilege.includes($store.state.user.role)" to="/admins" :name="$t('sidebar.admins')" icon="tim-icons icon-badge"/>
        <sidebar-link v-if="$store.state.prestige.includes($store.state.user.role)" to="/references" :name="$t('sidebar.references')" icon="tim-icons icon-coins"/>
        <sidebar-link v-if="$store.state.privilege.includes($store.state.user.role)" to="/promoters" :name="$t('sidebar.promoters')" icon="tim-icons icon-volume-98"/>
        <sidebar-link v-if="$store.state.privilege.includes($store.state.user.role)" to="/activation_codes" :name="$t('sidebar.activation-codes')" icon="tim-icons icon-credit-card"/>
        <sidebar-link v-if="$store.state.access.includes($store.state.user.role)" to="/transactions" :name="$t('sidebar.transactions')" icon="tim-icons icon-wallet-43"/>

      <!--<sidebar-link v-if="$store.state.privilege.includes($store.state.user.role)" to="/students" :name="$t('sidebar.student')" icon="tim-icons icon-badge"/>
        <sidebar-link v-if="$store.state.privilege.includes($store.state.user.role)" to="/teachers" :name="$t('sidebar.teacher')" icon="tim-icons icon-single-02"/>
      -->
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar ></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
  import TopNavbar from "./SampleNavbar.vue";
  import ContentFooter from "./SampleFooter.vue";
  import DashboardContent from "./Content.vue";
  import MobileMenu from "./MobileMenu";
  export default {
    components: {
      TopNavbar,
      ContentFooter,
      DashboardContent,
      MobileMenu
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },

    }
  };
</script>
