import Vue from 'vue'
import 'element-theme-dark'
import { Button, Table,Dialog, TableColumn, DatePicker, Tooltip, Select, Option, Pagination,Divider,Switch,Upload, Tag } from 'element-ui'
import lang from 'element-ui/lib/locale/lang/fr'
import locale from 'element-ui/lib/locale'
locale.use(lang)

Vue.use(Button)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(DatePicker)
Vue.use(Tooltip)
Vue.use(Select)
Vue.use(Option)
Vue.use(Pagination)
Vue.use(Divider)
Vue.use(Switch)
Vue.use(Upload)
Vue.use(Tag)
Vue.use(Dialog)
