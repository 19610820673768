import Vue from 'vue';
import Router from 'vue-router';
import DashboardLayout from '../layout/starter/SampleLayout.vue';
import Starter from '../layout/starter/SamplePage.vue';
import Dashboard from '../devPage/Dashboard.vue';
import Classes from '../devPage/Classes.vue';
import Subjects from '../devPage/Subjects.vue';
import ClasseSubject from '../devPage/AssociateSubXClass.vue'
import Types from '../devPage/Types.vue';
import Documents from '../devPage/Documents.vue';
import Students from '../devPage/Students.vue';
import Teachers from '../devPage/Teachers.vue';
import Login from '../devPage/Login.vue';
import Admins from '../devPage/Admins.vue';
import Promoters from '../devPage/Promoters.vue';
import References from '../devPage/References.vue';
import ActivationCodes from '../devPage/ActivationCode.vue';
import Transactions from '../devPage/Transactions.vue';



// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";
Vue.use(Router);

const routes=  [
    {
      path: '/',
      name: 'home',
      redirect: '/login',
      component: DashboardLayout,
      children: [ 
        {
          path: 'login',
          name: 'Connexion',
          components: { default: Login },
          meta: {
            guest: true,
          },
        },
        {
          path: 'dashboard',
          name: 'dashboard',
          components: { default: Dashboard },
          meta: {
            requiresAuth: true,
            is_access : true,
          },
        },
        {
          path: 'classes',
          name: 'classes',
          components: { default: Classes },
          meta: {
            requiresAuth: true,
            is_privilege: true,
          },
        },
        {
          path: 'subjects',
          name: 'matières',
          components: { default: Subjects },
          meta: {
            requiresAuth: true,
            is_privilege: true,

          },
        },
        {
          path: 'associate',
          name: 'association',
          components: { default: ClasseSubject },
          meta: {
            requiresAuth: true,
            is_privilege: true,
          },
        },
        {
          path: 'types',
          name: 'catégories',
          components: { default: Types },
          meta: {
            requiresAuth: true,
            is_privilege: true,
          },
        },
        {
          path: 'documents',
          name: 'document',
          components: { default: Documents },
          meta: {
            requiresAuth: true,
            is_access: true,
          },
        },
        {
          path: 'students',
          name: 'élèves',
          components: { default: Students },
          meta: {
            requiresAuth: true,
            is_privilege: true,
          },
        },
        {
          path: 'teachers',
          name: 'enseignants',
          components: { default: Teachers },
          meta: {
            requiresAuth: true,
            is_privilege: true,
          },
        },
        {
          path: 'admins',
          name: 'administrateurs',
          components: { default: Admins },
          meta: {
            requiresAuth: true,
            is_privilege: true,
          },
        },
        {
          path: 'promoters',
          name: 'promoteurs',
          components: { default: Promoters },
          meta: {
            requiresAuth: true,
            is_privilege: true,
          },
        },
        {
          path: 'references',
          name: 'Reference',
          components: { default: References },
          meta: {
            requiresAuth: true,
            is_prestige: true,
          },
        },
        {
          path: 'activation_codes',
          name: 'ActivationCode',
          components: { default: ActivationCodes },
          meta: {
            requiresAuth: true,
            is_privilege: true,
          },
        },
        {
          path: 'transactions',
          name: 'Transactions',
          components: { default: Transactions },
          meta: {
            requiresAuth: true,
            is_access: true,
          },
        }
      ]
    },
    { path: "*", component: NotFound },
  ]
;

export default routes;
