<template>
<div>
  <div class="row">
      <div class="col-12">
        <card >
          <template slot="header">
            <div class="row">
              <div class="col-sm-6" :class="'text-left'">           
                <h2 class="card-title">{{title}} - {{$t('tools.list')}}</h2>
              </div>
              
            </div>
          </template>
          <transaction-tools :rows="rows" @filter="filter"></transaction-tools>
          <blockquote class="blockquote mb-0">
            <vue-bootstrap4-table :rows="filtered" :columns="columns" :config="config"  
            :actions="actions"
            @refresh-data="onRefreshData"
            >
              <template slot="refresh-button-text">
                  <i class="fas fa-sync-alt"></i> {{$t('formButton.refresh')}}
              </template>
              <template slot="reset-button-text">
                  <i class="fas fa-broom"></i> {{$t('formButton.reset')}}
              </template>
              
               <template slot="status" slot-scope="props">
                <i v-if="props.cell_value == '201'" class="tim-icons icon-check-2" style="color : #13ce66"></i>
                <i v-else class="tim-icons icon-simple-remove" style="color : #ff4949"></i>
              </template>
               <template slot="createdAt" slot-scope="props">
                {{ $date(props.cell_value).format("DD MMM YYYY") }}
              </template>
              <template slot="pagination-info" slot-scope="props">
                {{ props.currentPageRowsLength ? $t('tables.pagination', {
                  countRow: props.currentPageRowsLength, 
                  countTotal:props.originalRowsLength,
                  countFilter: props.filteredRowsLength   
                }) : ''}}
              </template>
            </vue-bootstrap4-table>
          </blockquote>

        </card>
      </div>
    </div>
</div>
</template>

<script>
import VueBootstrap4Table from 'vue-bootstrap4-table'
import TransactionTools from './TransactionTools.vue';

export default {
  components: {
    VueBootstrap4Table,
    TransactionTools,
  },
  props:{
    title:String,
    rows:Array
  },
  data() {
    return {
        filtered:this.rows,
        ismounted: false,
        config: {
        rows_selectable: false,
        per_page: 25, // default 10
        pagination: true,
        per_page_options: [10, 25, 50, 100],
        highlight_row_hover_color: "#313846",
        global_search: {
            placeholder: this.$t('formField.search'),
            visibility: true,
            case_sensitive: false
        },
        
      },
     
      actions: [],
      columns: [{
              label: "#",
              name: "idusers",
              sort: true,
          },
          {
              label: "ID. Transaction",
              name: "key",
              filter: {
                  type: "simple",
                  placeholder: "ID. transaction"
              },
              sort: true,
          },
          
          {
              label: "Réference",
              name: "refs",
              filter: {
                  type: "simple",
                  placeholder: "Réference"
              },
          },
           {
              label: "Montant",
              name: "price",
              filter: {
                  type: "simple",
                  placeholder: "Montant payé"
              },
              sort: true,
          },
          {
              label: "Opérateur",
              name: "operateur",
              filter: {
                  type: "simple",
                  placeholder: "Opérateur"
              },
              sort: true,
          },{
              label: "Promoteur",
              name: "promoter",
              filter: {
                  type: "simple",
                  placeholder: "promoteur"
              },
              sort: true,
          },
         {
              label: "Date de transaction",
              name: "createdAt",
              sort: true,
          },
          {
              label: "Statut",
              name: "status",
              filter: {
                type: "select",
                mode: "single",
                closeDropdownOnSelection: true,
                placeholder: "Résultat transaction",
                options: [
                    {
                        "name": "Succès",
                        "value": "201"
                    }
                ]
              },
          }
      ],
      
    }
  },
  methods:{
    onRefreshData(){
      this.$emit('refresh')
    },
    filter(data){
      this.filtered = data
    }    
  },
  mounted(){
    this.ismounted = true
  },
  updated(){
    if(this.ismounted){
      this.filtered = this.rows
      this.ismounted = false
    }
  }
  
}
</script>

