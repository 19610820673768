import Axios from 'axios'
import config from './Config'
import Api from './Api'
export default {
	index() {
		return Api().get('classes')
	},
	insert(name) {
		return Api().post('classes', { name })
	},
	update(id, name) {
		return Api().put(`classes/${id}/`, { name })
	},
	delete(id) {
		return Api().delete(`classes/${id}/`)
	},
	url() {
		return config.baseUrl + 'classes'
	},
	getStatDoc() {
		return Api().get('classes/stats')
	},
}
