<template>
<div>
  
  <modal :show.sync="modal"
          body-classes="p-0"
          modal-classes="modal-dialog-centered modal-sm">
        <confirm-delete @confirm="destroy"/>
  </modal>
  <add-reference 
  v-if="add" 
  :title="$t('sidebar.references')" 
  :edit='edit' 
  :row='row'
  @created="create"
  @updated="update"
  @back-to-list="switchListener" />

  <reference-list :title="$t('sidebar.references')"
  index="idclasses"
  :tableData='tableData'
  @deleted='showModal'
  v-else @add="switchListener"></reference-list> 

</div>
</template>

<script>
import { BaseTable, Modal } from "@/components";
import AddReference from './Reference/AddReference.vue';
import ReferenceList from './Reference/ReferenceList.vue';
import ConfirmDelete from './Tools/ConfirmDelete.vue';
import ReferenceService from '@/services/ReferenceService'


export default {
  components: {
    BaseTable,
    Modal,
    AddReference,
    ReferenceList,
    ConfirmDelete
  },
  data() {
    return {
      modal:false,
      add:false,
      edit:false,
      iddelete:0,
      row:{},
      tableData: []
    }
  },
  methods:{
    switchListener(event,row){
      if(event=='edit'){
        this.edit = true;
        this.row = row;
      }else{
        this.edit = false;
        this.row = {};
      }
      this.add = !this.add;
    },
    showModal(id){
      this.iddelete = id;
      this.modal = true;
    },
    async getReferences(){
      try {
        const rep= (await ReferenceService.index()).data
        this.tableData = rep.rows
      } catch (error) {
        this.notifyError(error.response.data.error)
      }
    },
    async create(form){
      try {
        const response = (await ReferenceService.insert(form)).data
        this.notifySuccess('References créé '+ response.idrefs)
        this.getReferences()
      } catch (error) {
        console.log(error)
        this.notifyError(error.response.data.error)
      }
      
    },
    async update(id,form){
      try {
        (await ReferenceService.update(id, form)).data
        this.notifySuccess('References Modifié')
        this.getReferences()
      } catch (error) {
        this.notifyError(error.response.data.error)
      }
    },
    async destroy(){
      this.modal = false;
      try {
        (await ReferenceService.delete(this.iddelete)).data
        this.notifySuccess('Reference Supprimé ')
        this.getReferences()
      } catch (error) {
        this.notifyError(error.response.data.error)
      }
    },
    notifySuccess(message){
      this.$notify({verticalAlign: 'bottom',type: 'success', horizontalAlign: 'right', message: message});
    },
    notifyError(message){
      this.$notify({verticalAlign: 'bottom',type: 'danger', horizontalAlign: 'right', message: message});
    }
  },
  async mounted(){
    await this.getReferences()
  }
}
</script>