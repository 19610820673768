import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	strict: true,
	state: {
		refreshToken: null,
		token: null,
		user: null,
		isUserLoggedIn: false,
		access:['ASSOCIATE-PROF','ACADEMIA','CODARIS'],
		privilege : ['ACADEMIA','CODARIS'],
		prestige : ['CODARIS']
	},
	mutations: {
		setToken(state, token) {
			state.token = token
			if (token) {
				state.isUserLoggedIn = true
			} else {
				state.isUserLoggedIn = false
			}
		},
		setRefresh(state, refreshToken) {
			state.refreshToken = refreshToken
		},
		setUser(state, user) {
			state.user = user
		},
	},
	actions: {
		setToken({ commit }, token) {
			commit('setToken', token)
		},
		setUser({ commit }, user) {
			commit('setUser', user)
		},
		setRefresh({ commit }, refreshToken) {
			commit('setRefresh', refreshToken)
		},
	},
})
