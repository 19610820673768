/*
 =========================================================
 * Vue Black Dashboard - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/black-dashboard
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import VueRouter from "vue-router";

import RouterPrefetch from 'vue-router-prefetch'
import App from "./App";
// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import router from "./router/index";

import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n"
import { sync } from 'vuex-router-sync'
import NProgress from 'vue-nprogress'
import store from './store/store'
import './plugins/table.js'
import './registerServiceWorker'
import './plugins/element.js';
import '@/plugins/Dayjs';
Vue.use(NProgress)
Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
sync(store, router)

const nprogress = new NProgress()
/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  nprogress,
  render: h => h(App)
}).$mount("#app");
