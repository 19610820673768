<template>
  <div>
    <base-input
      label="Notions"
      v-model="inputValue"
      size="mini"
      @keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirm"
    >
    </base-input>
    <el-tag
    class="mb-2"
      :key="tag"
      v-for="tag in tags"
      closable
      effect="dark"
      type="warning"
      :disable-transitions="false"
      @close="handleClose(tag)">
      {{tag}}
    </el-tag>
  </div>
</template>

<style scoped>
  .el-tag + .el-tag {
    margin-left: 10px;
  }
</style>

<script>
  export default {
    props:{
      tags: Array
    },
    data() {
      return{
        inputValue:''
      }
    },
    methods: {
      handleClose(tag) {
        this.tags.splice(this.tags.indexOf(tag), 1);
      },

      handleInputConfirm() {
        let inputValue = this.inputValue;
        if (inputValue) {
          this.tags.push(inputValue);
        }
        this.inputValue = '';
      }
    }
  }
</script>