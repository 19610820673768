<template>
<div>
  <div class="row">
      <div class="col-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-6" :class="'text-left'">           
                <h2 class="card-title">{{$t('dashboard.class')}}</h2>
              </div>
              <div class="col-sm-6">
                <div class="btn-group"
                     :class="'float-right'">
                 <base-button size="sm" type="primary" @click="handleClick">
                  {{$t('tools.add')}}
                 </base-button>
                </div>
              </div>
            </div>
          </template>
         <div>
            <tables
              ref="table"
              :rows="rows"
              :columns="columns"
            ></tables>
          </div>
        </card>
      </div>
    </div>
</div>
</template>

<script>
import { BaseTable,Tables } from "@/components";
import ElementList from './ElementList.vue';

export default {
  components: {
      BaseTable,
      Tables,
    ElementList
    },
  data() {
    return {
      rows: [{
          "id": 1,
          "name": {
              "first_name": "Vladimir",
              "last_name": "Nitzsche"
          },
          "address": {
              "country": "Mayotte"
          },
          "email": "franecki.anastasia@gmail.com",
      },
      {
          "id": 2,
          "name": {
              "first_name": "Irwin",
              "last_name": "Bayer"
          },
          "age": 23,
          "address": {
              "country": "Guernsey"
          },
          "email": "rlittle@macejkovic.biz",
      },
      {
          "id": 3,
          "name": {
              "first_name": "Don",
              "last_name": "Herman"
          },
          "address": {
              "country": "Papua New Guinea"
          },
          "email": "delia.becker@cormier.com",
      },
      {
          "id": 4,
          "name": {
              "first_name": "Don",
              "last_name": "Herman"
          },
          "address": {
              "country": "Papua New Guinea"
          },
          "email": "delia.becker@cormier.com",
      },
      {
          "id": 5,
          "name": {
              "first_name": "Don",
              "last_name": "Herman"
          },
          "address": {
              "country": "Papua New Guinea"
          },
          "email": "delia.becker@cormier.com",
      },
      {
          "id": 6,
          "name": {
              "first_name": "Don",
              "last_name": "Herman"
          },
          "address": {
              "country": "Papua New Guinea"
          },
          "email": "delia.becker@cormier.com",
      },
      {
          "id": 7,
          "name": {
              "first_name": "Don",
              "last_name": "Herman"
          },
          "address": {
              "country": "Papua New Guinea"
          },
          "email": "delia.becker@cormier.com",
      }],
      columns: [{
              label: "id",
              name: "id",
              filter: {
                  type: "simple",
                  placeholder: "id"
              },
              sort: true,
          },
          {
              label: "First Name",
              name: "name.first_name",
              filter: {
                  type: "simple",
                  placeholder: "Enter first name"
              },
              sort: true,
          },
          {
              label: "Email",
              name: "email",
              sort: true,
          },
          {
              label: "Country",
              name: "address.country",
              filter: {
                  type: "simple",
                  placeholder: "Enter country"
              },
          }
      ]
    }
  },
  methods:{
    handleClick(evt) {
      this.$emit('click', evt);
    }
  }
}
</script>

