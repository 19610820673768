<template>
<div>
  <div class="row">
      <div class="col-12">
        <card type="nav-tabs">
          <div slot="header" class="card-header-success">
            <div class="row">
              <div class="col-sm-6" :class="'text-left'">           
                <h2 class="card-title"> {{title}} - {{edit? $t('tools.update'):$t('tools.create')}}</h2>
              </div>
              <div class="col-sm-6">
                <div class="btn-group"
                     :class="'float-right'">
                 <base-button size="sm" type="primary" @click="handleClick">
                  {{$t('tools.back')}}
                 </base-button>
                </div>
              </div>
            </div>
          
          </div>
          <blockquote class="blockquote mb-0">
            <ValidationObserver v-slot="{ invalid }">
              <form @submit.prevent="onSubmit">
                <validation-provider class="col " rules="required">
                  <base-input v-model="roles.selected" :label="$t('formField.role')">
                    <div>
                      <el-select class="select-danger"
                                :placeholder="$t('formField.selectRole')"
                                selectedable
                                :disabled ="!$store.state.prestige.includes($store.state.user.role)"
                                v-model="roles.selected">
                        <el-option v-for="option in roles.rows"
                                  class="select-danger"
                                  :value="option"
                                  :label="option"
                                  :key="option">
                        </el-option>
                      </el-select>
                    </div>
                  </base-input>
                </validation-provider>
                <div v-if="$store.state.privilege.includes(roles.selected)">
                <validation-provider rules="required|min:3" v-slot="{ passed,  failed,   errors }">
                  <base-input
                  required
                  v-model="fullname"
                  type="text"
                  :label="$t('formField.name')"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, {'is-invalid': failed},{'has-danger': failed}]"
                  >
                  </base-input>
                </validation-provider>
                </div>
                <div class="form-row">
                  <validation-provider class="col" rules="required|email" v-slot="{ passed,  failed,   errors }">
                    <base-input
                    required
                    v-model="email"
                    type="email"
                    :label="$t('formField.email')"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, {'is-invalid': failed},{'has-danger': failed}]"
                    >
                    </base-input>
                  </validation-provider>
                  <validation-provider v-if="$store.state.privilege.includes(roles.selected)" class="col" rules="required" v-slot="{ passed,  failed,   errors }">
                    <base-input
                    required
                    v-model="phone"
                    type="text"
                    :label="$t('formField.phone')"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, {'is-invalid': failed},{'has-danger': failed}]"
                    >
                    </base-input>
                  </validation-provider>
                </div>
                <div class="form-row" v-if="$store.state.privilege.includes(roles.selected)">
                  <validation-provider class="col" :rules="edit?'':'required'" vid="password" v-slot="{ passed,  failed,   errors }">
                    <base-input
                    v-model="password"
                    type="password"
                    :label="$t('formField.password')"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, {'is-invalid': failed},{'has-danger': failed}]"
                    >
                    </base-input>
                  </validation-provider>
                  <validation-provider  :rules="edit?'confirmed:password':'required|confirmed:password'"  class="col"  v-slot="{ passed,  failed,   errors }">
                    <base-input
                    type="password"
                    v-model="confirmation"
                    :label="$t('formField.confirmPass')"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, {'is-invalid': failed},{'has-danger': failed}]"
                    >
                    </base-input>
                  </validation-provider>
                </div>
                <base-checkbox v-model="stay">
                    {{$t('formField.stayOnPage')}}
                </base-checkbox>
                <base-button native-type="submit" type="primary" :disabled="invalid">{{edit? $t('formButton.edit'): $t('formButton.add')}}</base-button>
              </form>
            </ValidationObserver>
          </blockquote>
        </card>
      </div>
    </div>
</div>
</template>

<script>
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required, email, min, confirmed } from 'vee-validate/dist/rules';
import { configure } from 'vee-validate';

extend('required', {
  ...required,
  message: 'Ce champ est requis!'
});
extend('confirmed', {
  ...confirmed,
  message: 'Les mots de passe ne correspondent pas'
});
extend('email', {
  ...email,
  message: 'Entrez une adresse électronique valide'
});
extend('min', {
  ...min,
  message: 'Vous devez renseigner au moins 3 caractères'
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props:{
    title: String,
    edit: Boolean,
    row: Object
  },

  data() {
    return {
      stay:false,
      fullname:'',
      email:'',
      phone:'',
      password:'',
      confirmation:'',
      value:'',
      roles:{
        selected:'',
        rows: this.$store.state.access
      }
    }
  },
  methods:{
    handleClick(evt) {
      this.$emit('back-to-list', evt);
    },
    onSubmit () {
      const form = {
        fullname : this.fullname,
        email : this.email,
        role: this.roles.selected,
        phone : this.phone,
        password: this.password
      }
      if(this.edit){
        this.$emit('updated',this.row.idadmins,form)
      }else{
        this.$emit('created',form)
      }
      if(!this.stay){
        this.handleClick();
      }
    }
  },
  mounted(){
    if(this.row){
      this.fullname = this.row.name
      this.roles.selected = this.row.role
      this.email = this.row.email
      this.phone = this.row.contact
    }
    if(this.$store.state.privilege.includes(this.$store.state.user.role) && !this.$store.state.prestige.includes(this.$store.state.user.role))
    this.roles.selected = 'ASSOCIATE-PROF'
  }
}
</script>

