<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright">
        © {{year}} conçu par
        <a href="https://www.codaris.ga" target="_blank" rel="noopener">Codaris</a>
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    data() {
      return {
        year: new Date().getFullYear()
      }
    }
  };
</script>
<style>
</style>
