<template>
  <base-table :data="table.data"
              :columns="table.columns"
              thead-classes="text-primary">
  </base-table>
</template>
<script>
  import { BaseTable } from "@/components";
  import StatServices from '@/services/StatServices'

  export default {
    components: {
      BaseTable
    },
    data(){
      return{
        table:{
          columns:[
            {
              props: 'content',
              label: 'Problème'
            },
            {
              props: 'countResponse',
              label: 'Nbre de Réponse'
            },
          ],
          data:[]
        }
      }
    },
    computed: {
      
    },
    async mounted(){
      try {
        this.table.data = (await StatServices.getForumTop()).data
      } catch (error) {
        this.$notify({verticalAlign: 'bottom',type: 'danger', horizontalAlign: 'right', message: error.response.data.error});
      }
    }
  }
</script>
<style>
</style>
