<template>
<div>
  <div class="row">
      <div class="col-12">
        <card type="nav-tabs">
          <div slot="header" class="card-header-success">
            <div class="row">
              <div class="col-sm-6" :class="'text-left'">           
                <h2 class="card-title"> {{title}} - {{edit? $t('tools.update'):$t('tools.create')}}</h2>
              </div>
              <div class="col-sm-6">
                <div class="btn-group"
                     :class="'float-right'">
                 <base-button size="sm" type="primary" @click="handleClick">
                  {{$t('tools.back')}}
                 </base-button>
                </div>
              </div>
            </div>
          
          </div>
          <blockquote class="blockquote mb-0">
            <ValidationObserver v-slot="{ invalid }">
              <form @submit.prevent="onSubmit">
                <div>
                  <validation-provider rules="required|min:3" v-slot="{ passed,  failed,   errors }">
                    <base-input
                    required
                    v-model="fullname"
                    type="text"
                    :label="$t('formField.name')"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, {'is-invalid': failed},{'has-danger': failed}]"
                    >
                    </base-input>
                  </validation-provider>
                </div>
                <div class="form-row">
                  <validation-provider class="col" rules="required|email" v-slot="{ passed,  failed,   errors }">
                    <base-input
                    required
                    v-model="email"
                    type="email"
                    :label="$t('formField.email')"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, {'is-invalid': failed},{'has-danger': failed}]"
                    >
                    </base-input>
                  </validation-provider>
                  <validation-provider  class="col" rules="required" v-slot="{ passed,  failed,   errors }">
                    <base-input
                    required
                    v-model="phone"
                    type="text"
                    :label="$t('formField.phone')"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, {'is-invalid': failed},{'has-danger': failed}]"
                    >
                    </base-input>
                  </validation-provider>
                </div>
                <div>
                  <validation-provider rules="required|min:3" v-slot="{ passed,  failed,   errors }">
                    <base-input
                    required
                    v-model="description"
                    type="texterea"
                    :label="$t('formField.description')"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, {'is-invalid': failed},{'has-danger': failed}]"
                    >
                    </base-input>
                  </validation-provider>
                </div>
                <div class="form-row">
                  <validation-provider class="col" :rules="edit?'':'required'" vid="password" v-slot="{ passed,  failed,   errors }">
                    <base-input
                    v-model="password"
                    type="password"
                    :label="$t('formField.password')"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, {'is-invalid': failed},{'has-danger': failed}]"
                    >
                    </base-input>
                  </validation-provider>
                  <validation-provider  :rules="edit && !password ?'confirmed:password':'required|confirmed:password'"  class="col"  v-slot="{ passed,  failed,   errors }">
                    <base-input
                    type="password"
                    v-model="confirmation"
                    :label="$t('formField.confirmPass')"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, {'is-invalid': failed},{'has-danger': failed}]"
                    >
                    </base-input>
                  </validation-provider>
                </div>
                <base-checkbox v-model="stay">
                    {{$t('formField.stayOnPage')}}
                </base-checkbox>
                <base-button native-type="submit" type="primary" :disabled="invalid">{{edit? $t('formButton.edit'): $t('formButton.add')}}</base-button>
              </form>
            </ValidationObserver>
          </blockquote>
        </card>
      </div>
    </div>
</div>
</template>

<script>
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required, email, min, confirmed } from 'vee-validate/dist/rules';
import { configure } from 'vee-validate';

extend('required', {
  ...required,
  message: 'Ce champ est requis!'
});
extend('confirmed', {
  ...confirmed,
  message: 'Les mots de passe ne correspondent pas'
});
extend('email', {
  ...email,
  message: 'Entrez une adresse électronique valide'
});
extend('min', {
  ...min,
  message: 'Vous devez renseigner au moins 3 caractères'
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props:{
    title: String,
    edit: Boolean,
    row: Object
  },

  data() {
    return {
      stay:false,
      fullname:'',
      email:'',
      phone:'',
      password:null,
      confirmation:'',
      value:'',
      description: '',
    }
  },
  methods:{
    handleClick(evt) {
      this.$emit('back-to-list', evt);
    },
    onSubmit () {
      const data = {
        fullname : this.fullname,
        email : this.email,
        phone : this.phone,
      }
      const user = this.password ? { ...data, password: this.password} : data
      const promoter = {description : this.description}
      const form = {user, promoter}
      if(this.edit){
        this.$emit('updated',this.row.idpromoters,form)
      }else{
        this.$emit('created',form)
      }
      if(!this.stay){
        this.handleClick();
      }
    }
  },
  mounted(){
    if(this.row){
      this.fullname = this.row.name
      this.email = this.row.email
      this.phone = this.row.contact
      this.description = this.row.description
    }
   
  }
}
</script>

