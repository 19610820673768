import Axios from 'axios'
import config from './Config'
import Api from './Api'
export default {
  index() {
    return Api().get('references')
  },
  insert(formData) {
    return Api().post('references', formData)
  },
  update( id ,form) {
    return Api().put(`references/${id}/`, form)
  },
  delete(id) {
    return Api().delete(`references/${id}/`)
  },
  url() {
    return config.baseUrl + 'references'
  },
 
}
