<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-6" :class="'text-left'">
                <h5 class="card-category">{{$t('dashboard.totalShipments')}} {{bigLineChart.activeIndex ? countTeachers : countStudents}}</h5>
                <h2 class="card-title">{{$t('dashboard.performance')  }}  {{new Date().getFullYear()}}</h2>
              </div>
              <div class="col-sm-6">
                <div class="btn-group btn-group-toggle"
                     :class="'float-right'"
                     data-toggle="buttons">
                  <label v-for="(option, index) in bigLineChartCategories"
                         :key="option"
                         class="btn btn-sm btn-primary btn-simple"
                         :class="{active: bigLineChart.activeIndex === index}"
                         :id="index">
                    <input type="radio"
                           @click="initBigChart(index)"
                           name="options" autocomplete="off"
                           :checked="bigLineChart.activeIndex === index">
                    {{option}}
                  </label>
                </div>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <line-chart style="height: 100%"
                        ref="bigChart"
                        chart-id="big-line-chart"
                        :chart-data="bigLineChart.chartData"
                        :gradient-colors="bigLineChart.gradientColors"
                        :gradient-stops="bigLineChart.gradientStops"
                        :extra-options="bigLineChart.extraOptions">
            </line-chart>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12" :class="{'text-right': isRTL}">
        <doc-per-classes/>
      </div>
      <div class="col-lg-6 col-md-12" :class="{'text-right': isRTL}">
        <doc-per-subjects/>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
          <card class="card" :header-classes="{'text-right': isRTL}">
            <h4 slot="header" class="card-title">{{$t('dashboard.topDocument')}}</h4>
            <div class="table-responsive">
              <top-document></top-document>
            </div>
          </card>
      </div>
      <div class="col-lg-6 col-md-12">
          <card class="card" :header-classes="{'text-right': isRTL}">
            <h4 slot="header" class="card-title">{{$t('dashboard.topProblem')}}</h4>
            <div class="table-responsive">
              <top-problem></top-problem>
            </div>
          </card>
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';
import * as chartConfigs from '@/components/Charts/config';
import config from '@/config';
import TopDocument from './Dashboard/TopDocument.vue';
import TopProblem from './Dashboard/TopProblem.vue';
import DocPerClasses from './Dashboard/DocPerClasses';
import DocPerSubjects from './Dashboard/DocPerSubjects.vue';
import StatServices from '@/services/StatServices'

export default {
  name: 'dashboard-page',
  components: {
      LineChart,
      BarChart,
      TopDocument,
      TopProblem,
      DocPerClasses,
      DocPerSubjects
    },
  data() {
    return {
      countStudents: 0,
      countTeachers:0,
      students:[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      teachers:[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      chartCategories:['Apprenants', 'Enseignants'],
      bigLineChart: {
          allData: [],
          activeIndex: 0,
          chartData: {
            datasets: [{ }],
            labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
          },
          extraOptions: chartConfigs.purpleChartOptions,
          gradientColors: config.colors.primaryGradient,
          gradientStops: [1, 0.4, 0],
          categories: []
      },

    };
  },
  computed: {
      enableRTL() {
        return this.$route.query.enableRTL;
      },
      isRTL() {
        return this.$rtl.isRTL;
      },
      bigLineChartCategories() {
        return this.chartCategories;
      }
    },
  methods: {
      async getStats(){
        try {
          const rep = (await StatServices.getStudentStat()).data
          for(const data of rep){
            this.students[data.month - 1]= data.count
          }
          const reponse = (await StatServices.getTeacherStat()).data
          for(const data of reponse){
            this.teachers[data.month - 1]= data.count
          }
          this.bigLineChart.allData.push(this.students)
          this.bigLineChart.allData.push(this.teachers)
          const response = (await StatServices.dashboard()).data
          this.countStudents = response.countStudents
          this.countTeachers = response.countTeachers

        } catch (error) {
          console.log(error)
          this.$notify({verticalAlign: 'bottom',type: 'danger', horizontalAlign: 'right', message: error.response.data.error});
        }
      },
      initBigChart(index) {
        let chartData = {
          datasets: [{
            fill: true,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.bigLineChart.allData[index]
          }],
          labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
        }
        this.$refs.bigChart.updateGradients(chartData);
        this.bigLineChart.chartData = chartData;
        this.bigLineChart.activeIndex = index;
      }
    },
    async mounted() {
      this.i18n = this.$i18n;
      if (this.enableRTL) {
        this.i18n.locale = 'ar';
        this.$rtl.enableRTL();
      }
      await this.getStats();
      this.initBigChart(0);
    },
};
</script>
<style></style>
