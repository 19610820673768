<template>
<div>
  <div class="row d-print-none">
      <div class="col-12">
        <card type="nav-tabs">
          <div slot="header" class="card-header-success">
            <div class="row">
              <div class="col-sm-6" :class="'text-left'">           
                <h2 class="card-title"> {{title}} - {{edit? $t('tools.update'):$t('tools.create')}}</h2>
              </div>
              <div class="col-sm-6">
                <div class="btn-group"
                     :class="'float-right'">
                 <base-button size="sm" type="primary" @click="handleClick">
                  {{$t('tools.back')}}
                 </base-button>
                </div>
              </div>
            </div>
          
          </div>
          <blockquote class="blockquote mb-0">
            <ValidationObserver v-slot="{ invalid }">
              <form @submit.prevent="" >
                <div class="form-row">
                  <validation-provider class="col" rules="required|min:3" v-slot="{ passed,  failed,   errors }">
                    <base-input
                    required
                    v-model="name"
                    type="text"
                    :label="$t('formField.name')"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, {'is-invalid': failed},{'has-danger': failed}]"
                    >
                    </base-input>
                  </validation-provider>
                  <validation-provider  rules="required">
                    <base-input class="col" label="Année d'édition" v-model="year">
                    <div >
                      <el-date-picker
                        v-model="year"
                        type="year"
                        size="large"
                        placeholder="Année ">
                      </el-date-picker>
                    </div>
                    </base-input>
                  </validation-provider>
                </div>
                  <div class="form-row">
                    <validation-provider class="col" rules="required">
                      <base-input label="Sujet" v-model="doc.file">
                        <div class="form-row" >
                          <el-upload
                            v-model="doc.file"
                            class="upload-demo col"
                            :on-change="fileDoc"
                            :on-remove="fileRemoveDoc"
                            drag
                            accept="application/pdf"
                            :limit='1'
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :auto-upload="false">
                            <base-button slot="trigger" size="small" type="primary">Choisir un Sujet</base-button>
                          </el-upload>
                          <base-checkbox class="col" v-model="doc.status">
                                Payant
                          </base-checkbox>
                        </div>
                        <div class="mx-auto">
                        <span class="mx-auto text-muted" v-if="existDoc"
                          @click="showPdf(existDoc)">
                          <pdf
                              :src="existDoc"
                              @num-pages="pageCount = $event"
                              @page-loaded="currentPage = $event"
                              style="width:20%"
                          ></pdf>
                          </span>
                        </div>
                      </base-input>
                    </validation-provider>
                  <validation-provider class="col">
                    <base-input label="Correction">
                      <div class="form-row">
                        <el-upload
                          :on-change="fileAns"
                          :on-remove="fileRemoveAns"
                          class="upload-demo col"
                          ref="upload"
                          drag
                          :limit='1'
                          accept="application/pdf"
                          action="https://jsonplaceholder.typicode.com/posts/"
                          :auto-upload="false">
                          <base-button slot="trigger" size="small" type="primary">Choisir un Corrigé</base-button>
                        </el-upload>
                        <base-checkbox class="col" v-model="ans.status">
                            Payant
                        </base-checkbox>
                      </div>
                      <span class="text-center text-muted" v-if="existAns"
                        @click="showPdf(existAns)">
                        <pdf
                              :src="existAns"
                              
                              @num-pages="pageCount = $event"
                              @page-loaded="currentPage = $event"
                              style="width:20%"
                          ></pdf>
                      </span>
                    </base-input>
                  </validation-provider>
                  </div>
                  <div class="form-row">
                    <validation-provider class="col" rules="required">
                      <base-input v-model="types.selected" label="Catégorie" >
                        <div>
                          <el-select class="select-danger"
                                    :placeholder="$t('formField.selectType')"
                                    selectedable
                                    v-model="types.selected">
                            <el-option v-for="option in types.rows"
                                      class="select-danger"
                                      :value="option.idtypedocs"
                                      :label="option.name"
                                      :key="option.idtypedocs">
                            </el-option>
                          </el-select>
                        </div>
                      </base-input>
                    </validation-provider>
                    <validation-provider class="col " rules="required">
                      <base-input v-model="subjects.selected" label="Matière">
                        <div>
                          <el-select class="select-danger"
                                    :placeholder="$t('formField.selectSubject')"
                                    selectedable
                                    :disabled ="$store.state.user.idsubjects?true:false"
                                    @change="getClassesSubject"
                                    v-model="subjects.selected">
                            <el-option v-for="option in subjects.rows"
                                      class="select-danger"
                                      :value="option.idsubjects"
                                      :label="option.name"
                                      :key="option.idsubjects">
                            </el-option>
                          </el-select>
                        </div>
                      </base-input>
                    </validation-provider>
                  </div>
                <div class="col-12">
                  <validation-provider class="col " rules="required">
                    <base-input v-model="selectedClasse" >
                      <div class="row text-left pl-5">
                        <div
                          class="col col-md-2 col-12 col-sm-3 m-2"
                          v-for="classe in classes"
                          :key="classe.idclasses"
                        >
                          <base-checkbox
                            :disabled="classe.disabled"
                            @input="oncheck(classe)"
                            :checked="classe.status"
                            >{{ classe.name }}</base-checkbox
                          >
                        </div>
                      </div>
                    </base-input>
                  </validation-provider>
                </div>
                <div class="mt-4">
                  <input-tag :tags="tags"/>
                </div>
                <base-checkbox v-model="stay">
                    {{$t('formField.stayOnPage')}}
                </base-checkbox>
                <base-button  type="primary" :disabled="invalid" @click="onSubmit">{{edit? $t('formButton.edit'): $t('formButton.add')}}</base-button>
              </form>
               <el-dialog :visible.sync="dialogVisible" width="80%">
                 <div class="text-center">
                   {{currentPage}} / {{pageCount}}
                 </div>
                <div class="row">
                  <div class="col-1 my-auto"><base-button @click="previousPage" :disabled="i==1" size="sm"><i class="fa fa-chevron-left fa-lg"></i></base-button></div>
                  <div  class="col-10" >
                  <pdf
                    :src="dialogSrc"
                    @num-pages="pageCount = $event"
                    @page-loaded="currentPage = $event"
                    :key="i"
                    :page="i"
                    style="width:100%">
                  </pdf>
                  </div>
                  <div class="col-1 my-auto" ><base-button @click="nextPage" :disabled="i==pageCount" size="sm"><i class="fa fa-chevron-right fa-lg"></i></base-button></div>

                </div>
                
              </el-dialog>
            </ValidationObserver>
          </blockquote>
          {{doc.file.raw}}
        </card>
      </div>
    </div>
</div>
</template>

<script>
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import InputTag from './InputTag.vue';
import { required, min } from 'vee-validate/dist/rules';
import { configure } from 'vee-validate';
import ClasseService from '@/services/ClasseService'
import SubjectService from '@/services/SubjectService'
import TypeDocService from '@/services/TypeDocService'
import ClasseSubjectService from '@/services/ClasseSubjectService'
import DocService from '@/services/DocService'
import Api from '@/services/Api'

import Utils from '@/utils/Utils'
import pdf from 'vue-pdf'
import BaseButton from '../../components/BaseButton.vue';
extend('required', {
  ...required,
  message: 'Vous devez renseigner un nom'
});
extend('min', {
  ...min,
  message: 'Vous devez renseigner au moins 3 caractères'
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    InputTag,
    pdf,
    BaseButton
  },
  props:{
    title: String,
    edit: Boolean,
    row: Object
  },

  data() {
    return {
      stay:false,
      tags:[],
      name:'',
      existDoc :'',
      existAns:'',
      types: {
            selected: '',
            rows: []
          },
      subjects:{
        selected:'',
        rows:[]
      },
      classes: [],
      classeSubject:[],
      doc:{
        file:'',
        status:false
      },
      ans:{
        file:'',
        status:false
      },
      year:null,
      dialogSrc : '',
      dialogVisible: false,
      currentPage: 0,
      pageCount: 0,
      i:1
    }
  },
  computed : {
    selectedClasse(){
        var regclasses = []
        this.classes.forEach(classe => {
          if (classe.status === true) {
            regclasses.push( classe.idclasses)
          }
        })
        return regclasses
    }
  },
  methods:{
    handleClick(evt) {
      this.$emit('back-to-list', evt);
    },
    previousPage(){
      if(this.currentPage > 1)
        this.i = this.currentPage - 1
    },
    nextPage(){
      if(this.currentPage < this.pageCount)
        this.i = this.currentPage+1
    },
    fileDoc(file){
      this.doc.file = file
    },
    fileRemoveDoc(){
      this.doc.file = ''
    },
    fileAns(file){
      this.ans.file = file
    },
    fileRemoveAns(){
      this.ans.file = ''
    },
    async getClassesSubject(){
      this.resetStatus()
      this.getClasses()
    },
    showPdf(src){
      this.dialogVisible = true,
      this.dialogSrc = src,
      this.currentPage = 0,
      this.pageCount = 0,
      this.i = 1
    },
    async getClasses() {
      try {
        if (this.subjects.selected != -1) {
          const response = (
            await ClasseSubjectService.classe(this.subjects.selected)
          ).data

          this.classeSubject = response.subjectHasClasses
          this.classeSubject = this.classeSubject.map(element => {
            // delete element.subjectsHasClasses
            element['status'] = false
            element['disabled'] = true

            return element
          })
          this.Checked()
        } else {
        }
      } catch (error) {
        this.error = error.response.data.error
      }
    },
    Checked() {
      this.classes = this.classes.map(classe => {
        classe.disabled = !Utils(this.classeSubject, classe)
        return classe
      })
    },
    resetStatus() {
      this.classes = this.classes.map(classe => {
        classe['status'] = false
        classe['disabled'] = true
        return classe
      })
    },
    oncheck(classe1) {
      this.classes = this.classes.map(classe => {
        if (classe1.idclasses == classe.idclasses) {
          classe.status = !classe1.status
        }
        return classe
      })
      return classe1.status
    },
    onSubmit () {
      const formData = new FormData()
      formData.append('name', this.name)
      formData.append('year', this.year.getFullYear())
      formData.append('idtypedocs', this.types.selected)
      formData.append('idsubjects', this.subjects.selected)
      formData.append('status', this.doc.status)
      if(this.doc.file != this.existDoc && this.doc.file != null){
        formData.append('pathfile', this.doc.file.raw)
      }
      if(this.ans.file != this.existAns && this.ans.file != null){
        formData.append('answerfile', this.ans.file.raw)
        formData.append('answerstatus', this.ans.status)
      }
      this.selectedClasse.forEach(classe => {
          formData.append('idclasses', classe)
      })
      if(this.tags.length){
        formData.append('notions', this.tags.join(';'))
      }
      if(this.edit){
        this.$emit('updated',this.row.iddocuments, formData)
      }else{
        this.$emit('created',formData)
      }
      if(!this.stay){
        this.handleClick();
      }
    },
    async getFile(url){
      const resp = await Api().get(url,
        {
          responseType: 'arraybuffer',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/pdf'
          }
        })
      return resp
    }
  },
  async mounted(){
    
     const responseClasse = (await ClasseService.index()).data
    this.classes = responseClasse.rows
    const responseType = (await TypeDocService.index()).data
    this.types.rows = responseType.rows
    const responseSubject = (await SubjectService.index()).data
    this.subjects.rows = responseSubject.rows
    this.resetStatus()

    if(this.row.iddocuments){
      this.name= this.row.name
      this.existDoc = this.row.pathfile
      this.doc.file = this.row.pathfile
      this.doc.status = this.row.status
      this.existAns = this.row.pathAnswer
      this.ans.status = this.row.statusAnswer ? true : false
      this.year = this.row.year ?  new  Date(this.row.year.toString()) : null
      this.tags = this.row.notions ? this.row.notions.split(';') : []
      this.types.selected = this.row.idtypedocs
      this.subjects.selected = this.row.idsubjects
      await this.getClasses()
      this.row.idclasses.forEach(element => {
        this.classes = this.classes.map(classe => {
          if (classe.idclasses == element) {
            classe.status = true
          }
          return classe
        })
      })
      if(this.existDoc){
        this.existDoc = await this.getFile(this.doc.file)
      }
      if(this.existAns){
        this.ans.file = this.row.pathAnswer
        this.existAns = await this.getFile(this.ans.file)
      }
      
    }else{
      if(this.$store.state.user.idsubjects){
      this.subjects.selected = this.$store.state.user.idsubjects
      await this.getClasses()
    }
    }
    
  }
}
</script>

