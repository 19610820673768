<template>
<div>
   <div class="row">
      <div class="col-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-6" :class="'text-left'">           
                <h2 class="card-title">{{title}} - {{$t('tools.list')}}</h2>
              </div>
              <div class="col-sm-6">
                <div class="btn-group"
                     :class="'float-right'">
                 <base-button size="sm" type="primary" @click="handleClick">
                  {{$t('tools.add')}}
                 </base-button>
                </div>
              </div>
            </div>
          </template>
         <div>
            <el-table 
            :data="tableData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))">
              <el-table-column min-width="50" prop='idrefs' label="#"></el-table-column>
              <el-table-column min-width="150" prop="name"
                              :label="$t('tables.columns.name')" sortable>
              </el-table-column>
              <el-table-column min-width="200" prop="price"
                              :label="$t('formField.price')" sortable>
              </el-table-column>
              <el-table-column min-width="150" prop="day"
                              :label="$t('formField.day')" sortable>
              </el-table-column>
              <el-table-column min-width="150"
                              header-align="right"
                              label="Actions">
                <template slot="header" >
                  <base-input
                    v-model="search"
                    size="mini"
                    :placeholder="$t('formField.search')"/>
                </template>
                <div slot-scope="scope" class="text-right" >
                  <el-tooltip :content="$t('formButton.edit')"
                              :open-delay="300"
                              
                              placement="top">
                    <base-button type="success" size="sm" icon @click="edit(scope.row)">
                      <i class="tim-icons icon-settings"></i>
                    </base-button>
                  </el-tooltip>

                  <el-tooltip :content="$t('formButton.delete')"
                              :open-delay="300"
                              placement="top">
                    <base-button type="danger" size="sm" icon @click="deleted(scope.row)">
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </el-tooltip>

                </div>
              </el-table-column>
            </el-table>
          </div>
        </card>
      </div>
    </div>

</div>
</template>

<script>
  export default {
    props:{
      title:String,
      index: String,
      tableData: Array
    },
    data() {
      return {
        search:'',
      }
    },
    methods:{
      handleClick(evt,row) {
        this.$emit('add', evt,row);
      },
      edit(row){
        this.handleClick('edit',row);
      },
      deleted(row){
        this.$emit('deleted',row.idrefs)
      }
    }
  }
</script>
