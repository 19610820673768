import Axios from 'axios'
import config from './Config'
import Api from './Api'
export default {
  dashboard() {
    return Api().get('stats/dashboard')
  },
  getDocTop(){
    return Api().get('documents/top/stats')
  },
  getForumTop(){
    return Api().get('problems/top/stats')
  },
  getStudentStat(){
    return Api().get('students/stats')
  },
  getTeacherStat(){
    return Api().get('teachers/stats')
  },
}
