<template>
<div>
  
  <modal :show.sync="modal"
          body-classes="p-0"
          modal-classes="modal-dialog-centered modal-sm">
        <confirm-delete @confirm="deleted"/>
  </modal>
  <add-document 
  v-if="add" 
  :title="$t('sidebar.document')" 
  :edit='edit' 
  :row='row'
  @created="create"
  @updated="update"
  @back-to-list="switchListener" />

  <document-list :title="$t('sidebar.document')"
  :rows='documents'
  @delete-multiple="showModal"
  @deleted='showModal'
  @refresh="getDocuments"
  v-else @add="switchListener"></document-list>

</div>
</template>

<script>
import { BaseTable, Modal } from "@/components";
import DocumentList from './Document/DocumentList.vue';
import ConfirmDelete from './Tools/ConfirmDelete.vue';
import AddDocument from './Document/AddDocument.vue';
import ElementTablePagination from './Tools/ElementTablePagination.vue';
import DocService from '@/services/DocService'

export default {
  components: {
    BaseTable,
    Modal,
    DocumentList,
    ConfirmDelete,
    AddDocument,
    ElementTablePagination
  },
  data() {
    return {
      modal:false,
      add:false,
      edit:false,
      rowdelete:0,
      row:{},
      documents: []
    }
  },
  methods:{
    switchListener(event,row){
      if(event=='edit'){
        this.edit = true;
        this.row = row;
      }else{
        this.edit = false;
        this.row = {};
      }
      this.add = !this.add;
    },
    showModal(row){
      this.rowdelete = row;
      this.modal = true;
    },
    async getDocuments(){
      try {
        this.documents= (await DocService.getDocStat(this.$store.state.user.idsubjects)).data
        
      } catch (error) {
        this.notifyError(error.response.data.error)
      }
    },
    async create(form){
      try {
        const response = (await DocService.insert(form)).data
        this.notifySuccess('Document ajouté avec succes ' + response.iddocuments)
        this.getDocuments()
      } catch (error) {
        console.log(error)
        this.notifyError(error.response.data.error)
      }
    },
    async update(id,form){
      try {
        await DocService.update(id,form)
        this.notifySuccess('Document: mise à jour effectuée ')
        this.getDocuments()
      } catch (error) {
        console.log(error)
        this.notifyError(error.response.data.error)
      }    
    },
    async deleted(){
      if(Array.isArray(this.rowdelete)){
        for(const row of this.rowdelete){
          await this.destroy(row.iddocuments)
        }
      }else{
        await this.destroy(this.rowdelete.iddocuments)
      }
    },
    async destroy(id){
      this.modal = false;
      try {
        await DocService.delete(id)
        this.notifySuccess('Document supprimé')
        this.getDocuments()
      } catch (error) {
        console.log(error)
        this.notifyError(error.response.data.error)
      } 
    },
    notifySuccess(message){
      this.$notify({verticalAlign: 'bottom',type: 'success', horizontalAlign: 'right', message: message});
    },
    notifyError(message){
      this.$notify({verticalAlign: 'bottom',type: 'danger', horizontalAlign: 'right', message: message});
    }
  },
  async mounted(){
    await this.getDocuments()
  }
}
</script>