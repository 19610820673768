<template>
<div>
  <div class="row">
      <div class="col-12">
        <card type="nav-tabs">
          <div slot="header" class="card-header-success">
            <div class="row">
              <div class="col-sm-6" :class="'text-left'">           
                <h2 class="card-title">{{$t('sidebar.associatesubxclass')}}</h2>
              </div>
            </div>
          </div>
           <base-alert type="warning" v-show="warning">
              <strong>Attention!</strong> Données modifiées non enregistrer
          </base-alert>
          <blockquote class="blockquote mb-0">
            <div>
            <el-select class="select-danger"
                        :placeholder="$t('formField.selectClass')"
                        @change="getClasseSubject"
                        v-model="selects.selected" filterable>
                <el-option v-for="option in selects.classes"
                          class="select-danger"
                          :value="option.idclasses"
                          :label="option.name"
                          :key="option.idclasses">
                </el-option>
              </el-select>
            </div>
            <div class="col-12">
              <div class="row text-left pl-5">
                <div
                  class="col col-md-2 col-12 col-sm-3 m-2"
                  v-for="subject in subjects"
                  :key="subject.idsubjects"
                >
                  <base-checkbox
                    @input="oncheck(subject)"
                    :checked="subject.status"
                    >{{ subject.name }}</base-checkbox
                  >
                </div>
              </div>
            </div>
            <div class="text-center">
                <base-button @click="onSubmit" type="primary" class="my-4" :disabled="!warning" >{{$t('formButton.register')}} <span class="fas fa-spinner fa-pulse" v-if="isloading"></span></base-button>
            </div>
          </blockquote>
        </card>
      </div>
  </div>
</div>


 
</template>

<script>
  import {BaseAlert} from '@/components'
  import SubjectService from '@/services/SubjectService'
  import ClasseService from '@/services/ClasseService'
  import ClasseSubjectService from '@/services/ClasseSubjectService'
  import Utils from '@/utils/Utils'


  export default {
    components:{
      BaseAlert
    },
    data() {
      return {
        warning: false,
        isloading:false,
        selects: {
            selected: '',
            classes: []
          },
        subjects:[],
        classeSubject: []
      }
    },
    computed:{
    },
    methods:{
      async onSubmit(){
        try {
          this.isloading = true
          var regsubjects = []
          var regs = []
          regs=this.subjects.filter(subject=> subject.status==true)
          for(var i = 0; i<regs.length; i++){
            regsubjects.push(regs[i].idsubjects)
          }
          //regsubjects = regs.forEach(reg => regsubjects.push(reg.idsubjects))
          (await ClasseSubjectService.associate(this.selects.selected, regsubjects))
          this.isloading = false
          this.notifySuccess("Les matières ont correctement été associé")
          this.warning = false

        } catch (error) {
          this.isloading = false
          this.notifyError(error.response.data.error)
        }
      },
      async getClasseSubject(){
        try {
          this.subjects = this.subjects.map(subject => {
            subject['status'] = false
            return subject
          })
          this.warning = false

          if (this.selects.selected) {
            const response = (
              await ClasseSubjectService.index(this.selects.selected)
            ).data

            this.classeSubject = response.subjectHasClasses
            this.classeSubject = this.classeSubject.map(element => {
              delete element.subjectsHasClasses
              delete element.idclasses
              delete element.idsubjectshasclasses
              element['status'] = false
              return element
            })
            console.log(this.classeSubject)
            this.Checked()
          } else {
          }
        } catch (error) {
          this.error = error.response.data.error
        }
      },
      Checked() {
        this.subjects = this.subjects.map(subject => {
          subject.status = Utils(this.classeSubject, subject)
          return subject
        })
      },
      oncheck(subject1) {
        this.subjects = this.subjects.map(subject => {
          if (subject1.idsubjects == subject.idsubjects) {
            subject.status = !subject1.status
          }
          return subject
        })
        this.warning = this.selects.selected ? true : false
        return subject1.status
      },
      async getSubjects(){
        try {
          const rep= (await SubjectService.index()).data
          this.subjects = rep.rows
          this.subjects = this.subjects.map(subject => {
            subject['status'] = false
            return subject
          })
        } catch (error) {
          this.notifyError(error.response.data.error)
        }
      },
      async getClasses(){
        try {
          const rep= (await ClasseService.index()).data
          this.selects.classes = rep.rows
        } catch (error) {
          this.notifyError(error.response.data.error)
        }
      },  
      notifySuccess(message){
      this.$notify({verticalAlign: 'bottom',type: 'success', horizontalAlign: 'right', message: message});
      },
      notifyError(message){
        this.$notify({verticalAlign: 'bottom',type: 'danger', horizontalAlign: 'right', message: message});
      }
    },
    async mounted(){
      await this.getSubjects()
      await this.getClasses()
    }
  }
</script>
