import Axios from 'axios'
import config from './Config'
import Api from './Api'
export default {
  index() {
    return Api().get('transactions')
  },
  url() {
    return config.baseUrl + 'transactions'
  },
 
}
