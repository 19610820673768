<template>
<div>
  
  <modal :show.sync="modal"
          body-classes="p-0"
          modal-classes="modal-dialog-centered modal-sm">
        <confirm-delete @confirm="destroy"/>
  </modal>
  <add-activation-code 
  v-if="add" 
  :title="$t('sidebar.activation-codes')" 
  :edit='edit' 
  :row='row'
  @created="create"
  @updated="update"
  @back-to-list="switchListener" />

  <activation-code-list :title="$t('sidebar.activation-codes')"
  index="idclasses"
  :tableData='tableData'
  @deleted='showModal'
  v-else @add="switchListener"></activation-code-list> 

</div>
</template>

<script>
import { BaseTable, Modal } from "@/components";
import AddActivationCode from './ActivationCode/AddActivationCode.vue';
import ActivationCodeList from './ActivationCode/ActivationCodeList.vue';
import ConfirmDelete from './Tools/ConfirmDelete.vue';
import ActivationCodeService from '@/services/ActivationCodeService'


export default {
  components: {
    BaseTable,
    Modal,
    AddActivationCode,
    ActivationCodeList,
    ConfirmDelete
  },
  data() {
    return {
      modal:false,
      add:false,
      edit:false,
      iddelete:0,
      row:{},
      tableData: []
    }
  },
  methods:{
    switchListener(event,row){
      if(event=='edit'){
        this.edit = true;
        this.row = row;
      }else{
        this.edit = false;
        this.row = {};
      }
      this.add = !this.add;
    },
    showModal(id){
      this.iddelete = id;
      this.modal = true;
    },
    
    async getActivationCodes(){
      try {
        const rep= (await ActivationCodeService.index()).data
        this.tableData = rep.rows
      } catch (error) {
        this.notifyError(error.response.data.error)
      }
    },
    async create(id,form){
      try {
        const response = (await ActivationCodeService.insert(id,form)).data
        this.notifySuccess('ActivationCodes créé '+ response.idcodes)
        this.getActivationCodes()
      } catch (error) {
        console.log(error)
        this.notifyError(error.response.data.error)
      }
      
    },
    async update(id,form){
      try {
        (await ActivationCodeService.update(id, form)).data
        this.notifySuccess('ActivationCodes Modifié')
        this.getActivationCodes()
      } catch (error) {
        this.notifyError(error.response.data.error)
      }
    },
    async destroy(){
      this.modal = false;
      try {
        (await ActivationCodeService.delete(this.iddelete)).data
        this.notifySuccess('Code d\' activation Supprimé ')
        this.getActivationCodes()
      } catch (error) {
        this.notifyError(error.response.data.error)
      }
    },
    notifySuccess(message){
      this.$notify({verticalAlign: 'bottom',type: 'success', horizontalAlign: 'right', message: message});
    },
    notifyError(message){
      this.$notify({verticalAlign: 'bottom',type: 'danger', horizontalAlign: 'right', message: message});
    }
  },
  async mounted(){
    await this.getActivationCodes()
  }
}
</script>