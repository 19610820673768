<template>

  <card type="chart">
    <template slot="header">
      <h5 class="card-category">{{$t('dashboard.class')}}</h5>
      <h3 class="card-title"><i class="tim-icons icon-delivery-fast text-info "></i> {{$t('dashboard.docperclass')}}</h3>
    </template>
    <div class="chart-area">
      <bar-chart style="height: 100%"
                  chart-id="blue-bar-chart"
                  :chart-data="initChart.chartData"
                  :gradient-stops="initChart.gradientStops"
                  :extra-options="initChart.extraOptions">
      </bar-chart>
    </div>
  </card>
</template>
<script>
import BarChart from '@/components/Charts/BarChart';
import * as chartConfigs from '@/components/Charts/config';
import config from '@/config';
import ClasseService from '@/services/ClasseService'

export default {
  components:{
    BarChart
  },
  data() {
    return {
      classes:[],
      label: [],
      dataDoc : [],
      dataAns : [],
    };
  },
  computed:{
    initChart(){
      return {
        extraOptions: chartConfigs.barChartOptions,
        chartData: {
          labels:this.label,
          datasets: [{
            label: "Document",
            fill: true,
            borderColor: config.colors.info,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: this.dataDoc,
          },
          {
            label: "Corrigé",
            fill: true,
            borderColor: config.colors.danger,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: this.dataAns,
          }]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
      }
    }
  },
  methods:{
    async getClasses(){
      try {
        const rep= (await ClasseService.getStatDoc()).data
        this.classes = rep.rows
      } catch (error) {
        this.notifyError(error.response.data.error)
      }
    },
    getChart(){
      this.label =  []
      this.dataDoc = []
      this.dataAns = []

      this.classes.forEach((classe)=>{
        this.label.push(classe.name)
        this.dataDoc.push(classe.countDoc)
        this.dataAns.push(classe.countAnswerDoc)

      })
    }
  },
  mounted(){
    this.getClasses().then((_)=> this.getChart())
    
  }
};
</script>
<style></style>
