<template>
  <card type="secondary"
      header-classes="bg-white pb-5"
      body-classes="px-lg-5 py-lg-5"
      class="border-0 mb-0">
    <template>
        <div class="text-muted text-center mb-3">
            <small>{{$t('formField.confirmDel')}}</small>
        </div>
    </template>
    <template>
        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="onSubmit">
            <validation-provider rules="required" v-slot="{failed,   errors }">
              <base-input alternative
                          type="password"
                          v-model="password"
                          placeholder="Mot de passe"
                          :error="errors[0]"
                          :class="[{'is-invalid': failed}]">
              </base-input>
            </validation-provider>
            <div class="text-center">
                <base-button native-type="submit" type="primary" class="my-4" :disabled="invalid">{{$t('formButton.confirm')}}</base-button>
            </div>
          </form>
        </ValidationObserver>
    </template>
  </card>
</template>
<script>
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import Config from '@/services/Config'
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Vous devez renseigner un mot de passe'
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      password:''
    };
  },
  methods:{
    onSubmit(evt){
      if(this.password == Config.password){
        this.$emit('confirm',evt)
        this.password = ''
      }else{
        this.$notify({verticalAlign: 'bottom',type: 'danger', horizontalAlign: 'right', message: "Mote de passe incorrect"});
      }
      
    }
  }
};
</script>
<style></style>           
  
