<template>
<div>
  <transaction-list :title="$t('sidebar.transactions')"
  :rows='transactions'
  @refresh="getTransactions"
    ></transaction-list>

</div>
</template>

<script>
import { BaseTable, Modal } from "@/components";
import TransactionList from './Transaction/TransactionList.vue';

import ElementTablePagination from './Tools/ElementTablePagination.vue';
import TransactionService from '@/services/TransactionService'

export default {
  components: {
    BaseTable,
    Modal,
    TransactionList,
    ElementTablePagination
  },
  data() {
    return {
      modal:false,
      add:false,
      edit:false,
      rowdelete:0,
      row:{},
      transactions: []
    }
  },
  methods:{
    
    
    async getTransactions(){
      try {
        this.transactions= (await TransactionService.index()).data.rows
        
      } catch (error) {
        this.notifyError(error.response.data.error)
      }
    },
    notifySuccess(message){
      this.$notify({verticalAlign: 'bottom',type: 'success', horizontalAlign: 'right', message: message});
    },
    notifyError(message){
      this.$notify({verticalAlign: 'bottom',type: 'danger', horizontalAlign: 'right', message: message});
    }
  },
  async mounted(){
    await this.getTransactions()
  }
}
</script>