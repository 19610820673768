<template>
  <div class="d-flex  justify-content-center my-auto ">

    <div class="d-block d-md-none text-center ">
      <div><img src="logo.png" alt="Academia Gabon" style="width: 60%"></div>
      <div>Veuillez utiliser un écran plus large, de préférence un Ordinateur!</div>
    </div>

    <card class="col-6 d-none d-md-block" >
      <div class="btn-wrapper text-center">
        <div><img src="logo.png" alt="Academia Gabon" style="width: 60%"></div>
      </div>
      <div class="text-center text-muted mb-4">
          <small>Connexion au Panneau d'Administration</small>
      </div>
        <blockquote class="blockquote mb-0">
          <ValidationObserver v-slot="{ invalid }">
            <form role="form" @submit.prevent="login" >
              <validation-provider  rules="required|email" v-slot="{ errors }">
                <base-input alternative
                            class="mb-3"
                            v-model="email"
                            :error="errors[0]"
                            placeholder="Adresse électronique"
                          >
                </base-input>
              </validation-provider>
              <validation-provider  rules="required" v-slot="{ errors }">
                <base-input alternative
                            type="password"
                            v-model="password"
                            :error="errors[0]"
                            placeholder="Mot de passe"
                          >
                </base-input>
              </validation-provider>
                <div class="text-center">
                    <base-button native-type="submit" type="primary" class="my-4" :disabled="invalid">Connexion</base-button>
                </div>
            </form>
          </ValidationObserver>
        </blockquote>
      <div class="text-right text-muted mb-4 d-none d-md-block">
          <small>Mot de passe oublié?</small>
      </div>
    </card>
  </div>
</template>
<script>
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required, min, email } from 'vee-validate/dist/rules';
import { configure } from 'vee-validate';
import AuthentificationServices from '@/services/AuthentificationServices'
import localStore from '@/store/localstorageservice.js'

extend('required', {
  ...required,
  message: 'Champs requis'
});
extend('email', {
  ...email,
  message: 'Entrez une adresse électronique valide'
});
export default {
  name: 'login',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      email: '',
      password: '',
      
    };
  },
  methods:{
    async login() {
      try {
          const response = (await AuthentificationServices.login(this.email, this.password))
            .data
          if (
            response.role == 'CODARIS' ||
            response.role == 'ACADEMIA' || response.role == 'ASSOCIATE-PROF'
          ) {
            this.$store.dispatch('setUser', {
              idusers: response.idusers,
              fullname: response.fullname,
              email: response.email,
              role: response.role,
              idsubjects : response.module 
            })
            localStore.setToken({
              access_token: response.token,
              refresh_token: response.refreshToken,
            })
            this.$store.dispatch('setToken', response.token)
            this.$store.dispatch('setRefresh', response.refreshToken)
            if (this.$route.params.nextUrl != null) {
              this.$router.push(this.$route.params.nextUrl)
            } else {
              this.$router.push('/dashboard')
            }
          } else {
            this.$notify({verticalAlign: 'bottom',type: 'danger', horizontalAlign: 'right', message: "Utilisateurs : Les informations entrées sont incorrects" });
          }

         
        
      } catch (error) {
        console.log(error)
        this.$notify({verticalAlign: 'bottom',type: 'danger', horizontalAlign: 'right', message: error.response.data.error });
      }
    },
  }
};
</script>
<style></style>
