<template>
        <vue-bootstrap4-table :rows="rows" :columns="columns" :config="config">
        <slot>
        </slot>
        </vue-bootstrap4-table>
</template>
 
<script>
import VueBootstrap4Table from 'vue-bootstrap4-table'
 
export default {
    name: 'table',
    props:{
      rows:Array,
      columns:Array
    },
    data: function() {
        return {
            config: {
                checkbox_rows: true,
                rows_selectable: true,
                per_page: 10, // default 10
                pagination: true,
                show_reset_button: false,
            }
        }
    },
    components: {
        VueBootstrap4Table
    }
}
</script>